import React, { PureComponent } from 'react'
import { Sprite } from 'react-pixi-fiber'

import { centerBottomAnchor } from '../utils'
import { lightTexture } from '../textures'

export default class Light extends PureComponent {
  render () {
    return (
      <Sprite anchor={centerBottomAnchor} x={260} y={10} texture={lightTexture} />
    )
  }
}
