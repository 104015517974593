import * as PIXI from 'pixi.js'

export const sunTexture = PIXI.Texture.fromImage(require('./sun.png'))
export const forestTexture = PIXI.Texture.fromImage(require('./forest.png'))
export const lakeTexture = PIXI.Texture.fromImage(require('./lake.png'))
export const todorTexture = PIXI.Texture.fromImage(require('./pmdas.png'))
export const mahaTexture = PIXI.Texture.fromImage(require('./mmdasi.png'))
export const cloudTexture = PIXI.Texture.fromImage(require('./cloud.png'))
export const lightTexture = PIXI.Texture.fromImage(require('./light.png'))
export const dogTexture = PIXI.Texture.fromImage(require('./dog.png'))
export const grandDadTexture = PIXI.Texture.fromImage(require('./granddad.png'))
export const lilyTexture = PIXI.Texture.fromImage(require('./lily.png'))
export const flower1Texture = PIXI.Texture.fromImage(require('./flower1.png'))
export const flower2Texture = PIXI.Texture.fromImage(require('./flower2.png'))
export const speakBubbleTexture = PIXI.Texture.fromImage(require('./speak-bubble.png'))
