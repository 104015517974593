import React, { PureComponent } from 'react'
import { Sprite } from 'react-pixi-fiber'

import { centerBottomAnchor } from '../utils'
import { grandDadTexture } from '../textures'

export default class GrandDad extends PureComponent {
  render () {
    return (
      <Sprite anchor={centerBottomAnchor} x={132} y={10} texture={grandDadTexture} />
    )
  }
}
