import React, { PureComponent } from 'react'
import { Sprite } from 'react-pixi-fiber'

import store from '../store'
import { centerBottomAnchor } from '../utils'
import SpeakBubble from './SpeakBubble'
import { mahaTexture, todorTexture } from '../textures'

export default class TodorMaha extends PureComponent {
  currentPhraseNumber = -1

  state = {
    currentPhrase: null,
  }

  componentDidMount() {
    setTimeout(this.speak, 500)

    this.todor.on('pointerdown', this.speak)
  }

  componentWillUnmount() {
    this.todor.removeAllListeners()
  }

  speak = () => {
    if (this.state.currentPhrase) {
      this.completeCurrentPhrase()
    }

    this.currentPhraseNumber++

    if (this.currentPhraseNumber === phrases.length) {
      this.currentPhraseNumber = -1
      return
    }

    const currentPhrase = phrases[this.currentPhraseNumber]
    const timer = setTimeout(this.speak, currentPhrase.timeout)
    this.setState({ currentPhrase: { text: currentPhrase.text, timer } })
  }

  completeCurrentPhrase = () => {
    clearTimeout(this.state.currentPhrase.timer)
    this.setState({ currentPhrase: null })
  }

  setTodorRef = ref => (this.todor = ref)

  render() {
    const x = Math.min(store.appWidth * 0.65, store.appWidth * 0.5 + 60)
    const y = Math.min(store.appHeight * 0.83, store.appHeight * 0.60 + 100)
    return (
      <React.Fragment>
        <Sprite
          ref={this.setTodorRef}
          anchor={centerBottomAnchor}
          scale={0.5}
          x={x}
          y={y}
          texture={todorTexture}
          buttonMode
          interactive
          {...this.props} />

        <Sprite
          anchor={centerBottomAnchor}
          scale={0.5}
          x={x + 75}
          y={y}
          texture={mahaTexture}
          {...this.props} />

        {this.state.currentPhrase &&
          <SpeakBubble
            x={x - 15}
            y={y - 95}
            text={this.state.currentPhrase.text}
            speak={this.speak}
          />
        }
      </React.Fragment>
    )
  }
}

const phrases = [
  { text: 'Hi, I\'m Fedir, inquiring mobile developer...', timeout: 6000 },
  { text: 'Want to chit-chat with me?', timeout: 4500 },
  { text: 'Drop me a line to email 1@todor.one', timeout: 7000 },
  { text: '...or visit my GH page: github.com/todorone', timeout: 7000 },
  { text: '...either welcome to Li: linkedin.com/in/todorone', timeout: 7500 },
  { text: 'I live in cozy house at the edge of the world', timeout: 6000 },
  { text: 'I ❤️ React Native btw...', timeout: 6000 },
  { text: '...and passionate about great looking UI...', timeout: 6500 },
  { text: '...shiny, polished and animations packed.', timeout: 8000 },
  { text: 'That\'s basically it. Nothing more to add...', timeout: 10000 },
  { text: 'Are You still there? Come on! (:', timeout: 10000 },
]
