import React, { PureComponent } from 'react'
import { Sprite, Text } from 'react-pixi-fiber'
import { Back, TweenMax } from 'gsap'

import { speakBubbleTexture } from '../textures'
import { bottomRightAnchor, centerAnchor } from '../utils'

export default class SpeakBubble extends PureComponent {
  scale = 0
  alpha = 0
  deltaY = 50

  componentDidMount() {
    TweenMax.to(this, .25, {
      scale: 0.65,
      alpha: 1,
      deltaY: 0,
      ease: Back.easeOut,
      onUpdate: () => this.forceUpdate(),
    })

    this.bubble.on('pointerdown', this.props.speak)
  }

  componentWillUnmount() {
    this.bubble.removeAllListeners()
  }

  setRef = ref => (this.bubble = ref)

  render () {
    const { y, ...restProps } = this.props
    return (
      <Sprite
        ref={this.setRef}
        anchor={bottomRightAnchor}
        alpha={this.alpha}
        scale={this.scale}
        y={y + this.deltaY}
        texture={speakBubbleTexture}
        buttonMode
        interactive
        {...restProps}
      >
        <Text
          x={-speakBubbleTexture.width / 2}
          y={-speakBubbleTexture.height / 2 + 6}
          text={this.props.text}
          style={{
            align: 'center',
            fill: '#222',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            fontSize: '24px',
            fontWeight: '300',
            lineHeight: 45,
            wordWrap: true,
            wordWrapWidth: speakBubbleTexture.width * 4 / 5,
          }}
          anchor={centerAnchor} />
      </Sprite>
    )
  }
}
