import React, { PureComponent } from 'react'
import { Sprite } from 'react-pixi-fiber'
import { Linear, TimelineMax } from 'gsap'

import { centerBottomAnchor } from '../utils'
import { flower2Texture } from '../textures'

const SPEED = 0.03

export default class Flower2 extends PureComponent {
  sprite

  initialRotation = -Math.random() / 6

  componentDidMount() {
    const targetRotation = Math.random() / 6
    const timeline = new TimelineMax({ repeat: -1, yoyo: true })
    timeline.to(this.sprite, (targetRotation - this.initialRotation) / SPEED, { rotation: targetRotation, ease: Linear.easeNone })
  }

  render () {
    return (
      <Sprite
        ref={ref => this.sprite = ref}
        anchor={centerBottomAnchor}
        x={this.props.x}
        y={this.props.y}
        rotation={this.initialRotation}
        texture={flower2Texture}
      />
    )
  }
}
