import React, { PureComponent } from 'react'
import { Sprite } from 'react-pixi-fiber'

import store from '../store'
import { centerBottomAnchor } from '../utils'
import { forestTexture } from '../textures'
import Light from './Light'
import GrandDad from './GrandDad'
import Flower1 from './Flower1'
import Flower2 from './Flower2'

export default class Forest extends PureComponent {
  render () {
    return (
      <Sprite
        anchor={centerBottomAnchor}
        scale={store.isSmallDevice ? 0.3 : 0.5}
        x={store.appWidth * 0.5}
        y={store.appHeight * 0.5}
        texture={forestTexture}
      >
        <Light />

        <GrandDad />

        <Flower1 x={-400} y={20} />
        <Flower1 x={-830} y={20} />
        <Flower1 x={-950} y={20} />
        <Flower1 x={830} y={20} />
        <Flower1 x={510} y={20} />

        <Flower2 x={-340} y={20} />
        <Flower2 x={-890} y={20} />
        <Flower2 x={550} y={20} />
      </Sprite>
    )
  }
}
