import React, { PureComponent } from 'react'
import { Sprite } from 'react-pixi-fiber'

import store from '../store'
import { centerTopAnchor } from '../utils'
import { lakeTexture } from '../textures'
import Lily from './Lily'

export default class Lake extends PureComponent {
  render () {
    return (
      <Sprite
        anchor={centerTopAnchor}
        scale={0.5}
        x={store.appWidth * 0.3}
        y={store.appHeight * 0.53}
        texture={lakeTexture}
      >
        <Lily x={-90} y={230} distance={100} />
        <Lily x={-200} y={160} distance={360} />
        <Lily x={140} y={60} distance={140} />
      </Sprite>
    )
  }
}
