import React, { PureComponent } from 'react'
import { Sprite } from 'react-pixi-fiber'
import { Linear, TimelineMax } from 'gsap'

import store from '../store'
import { centerAnchor } from '../utils'
import { cloudTexture } from '../textures'

const SPEED = 15

export default class Cloud extends PureComponent {
  constructor(props) {
    super()

    this.x = props.progress * store.appWidth
  }

  componentDidMount() {
    const timeline = new TimelineMax({ repeat: -1 })
    timeline
      .to(this.spriteRef, (store.appWidth * (1 - this.props.progress) + 60) / SPEED, {
        x: store.appWidth + 60,
        ease: Linear.easeNone,
      })
      .fromTo(this.spriteRef, (this.props.progress * store.appWidth + 60) / SPEED, { x: -60, immediateRender: false }, {
        x: this.props.progress * store.appWidth,
        ease: Linear.easeNone,
      })
  }

  render () {
    return (
      <Sprite
        ref={ref => this.spriteRef = ref}
        anchor={centerAnchor}
        scale={0.5 + (this.props.y / store.appHeight - 0.15) * 1.25}
        x={this.x}
        texture={cloudTexture}
        {...this.props} />
    )
  }
}
