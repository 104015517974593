import React, { Component } from 'react'
import { Stage } from 'react-pixi-fiber'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'

import store from './store'
import Ground from './components/Ground'
import Sun from './components/Sun'
import Forest from './components/Forest'
import Lake from './components/Lake'
import TodorMaha from './components/TodorMaha'
import Cloud from './components/Cloud'
import Dog from './components/Dog'

const options = {
  backgroundColor: 0x37a4e7,
  antialias: true,
  autoResize: true,
  resolution: 2,
}

export default class App extends Component {
  render () {
    const { appWidth, appHeight } = store
    return (
      <Stage width={appWidth} height={appHeight} options={options}>
        <Sun />

        <Forest />

        <Ground />

        <Lake />

        <Dog />

        <Cloud progress={0.1} y={store.appHeight * 0.05} />
        {store.appWidth > 700 && <Cloud progress={0.3} y={store.appHeight * 0.18} />}
        <Cloud progress={0.5} y={store.appHeight * 0.26} />
        {store.appWidth > 700 && <Cloud progress={0.7} y={store.appHeight * 0.12} />}
        <Cloud progress={0.9} y={store.appHeight * 0.19} />

        <TodorMaha />
      </Stage>
    )
  }
}

decorate(App, { render: observer })
