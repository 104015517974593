import React, { PureComponent } from 'react'
import { Sprite } from 'react-pixi-fiber'

import store from '../store'
import { centerBottomAnchor } from '../utils'
import { dogTexture } from '../textures'

export default class Dog extends PureComponent {
  render () {
    return (
      <Sprite
        anchor={centerBottomAnchor}
        scale={0.5}
        x={store.appWidth * 0.21}
        y={store.appHeight * 0.75}
        texture={dogTexture} />
    )
  }
}
