import React, { PureComponent } from 'react'
import { Sprite } from 'react-pixi-fiber'
import * as PIXI from 'pixi.js'

import store from '../store'

export default class Ground extends PureComponent {
  render () {
    return (
      <Sprite
        width={store.appWidth}
        height={store.appHeight * 0.5}
        x={0}
        y={store.appHeight * 0.5}
        texture={PIXI.Texture.WHITE}
        tint={0x22BF5B}
        {...this.props} />
    )
  }
}
