import { decorate, observable } from 'mobx'

class Store {
  appWidth = window.innerWidth
  appHeight = window.innerHeight
  isSmallDevice = this.appWidth < 500
}

decorate(Store, {
  appWidth: observable,
  appHeight: observable,
})

export default new Store()
