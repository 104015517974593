import React, { PureComponent } from 'react'
import { Sprite } from 'react-pixi-fiber'
import { Linear, TimelineMax } from 'gsap'

import { centerBottomAnchor } from '../utils'
import { lilyTexture } from '../textures'

const SPEED = 6

export default class Lily extends PureComponent {
  sprite

  componentDidMount() {
    const { distance, x } = this.props

    const timeline = new TimelineMax({ repeat: -1, yoyo: true })
    timeline.to(this.sprite, distance / SPEED, { x: x + distance, ease: Linear.easeNone })
  }

  render () {
    return (
      <Sprite
        ref={ref => this.sprite = ref}
        anchor={centerBottomAnchor}
        x={this.props.x}
        y={this.props.y}
        texture={lilyTexture}
      />
    )
  }
}
