import React, { PureComponent } from 'react'
import { Sprite } from 'react-pixi-fiber'
import { TweenMax, Linear } from 'gsap'

import store from '../store'
import { centerAnchor } from '../utils'
import { sunTexture } from '../textures'

export default class Sun extends PureComponent {
  componentDidMount() {
    TweenMax.to(this.spriteRef, 20, { rotation: 2 * Math.PI, ease: Linear.easeNone, repeat: -1 })
  }

  render () {
    return (
      <Sprite
        ref={ref => this.spriteRef = ref}
        anchor={centerAnchor}
        scale={store.isSmallDevice ? 0.4 : 0.5}
        rotation={0}
        x={store.appWidth * 0.7}
        y={store.appHeight * 0.1}
        texture={sunTexture}
        {...this.props} />
    )
  }
}
